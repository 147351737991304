import { template as template_a86c948b387547fb9f56b5085505b4ae } from "@ember/template-compiler";
const FKControlMenuContainer = template_a86c948b387547fb9f56b5085505b4ae(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
