import { template as template_c7f0c8e7fc6c4e4ab6720d0e2cd62134 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c7f0c8e7fc6c4e4ab6720d0e2cd62134(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
