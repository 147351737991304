import { template as template_c6839607410546f2a84560c8c4fba925 } from "@ember/template-compiler";
const WelcomeHeader = template_c6839607410546f2a84560c8c4fba925(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
