import { template as template_85599dc57924478c95cc88f939677637 } from "@ember/template-compiler";
const FKText = template_85599dc57924478c95cc88f939677637(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
