import { template as template_8adce408204249a6a381989b6b50809b } from "@ember/template-compiler";
const FKLabel = template_8adce408204249a6a381989b6b50809b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
