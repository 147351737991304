import { template as template_d1ab4a31f8b14ee0bbc80c5f54341c40 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_d1ab4a31f8b14ee0bbc80c5f54341c40(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
